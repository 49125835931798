import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import Image from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started-as-a-designer",
      "style": {
        "position": "relative"
      }
    }}>{`Getting started as a Designer`}<a parentName="h1" {...{
        "href": "#getting-started-as-a-designer",
        "aria-label": "getting started as a designer permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    The HDS design kit contains all the resources you need to get started designing beautiful and accessible user experiences that follow the City of Helsinki brand.
    </LeadParagraph>
    <p>{`The HDS design libraries are the single point of reference for your design. When you connect your design files with the design system libraries, you can have the components synced whenever the design system is updated.`}</p>
    <h2 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h2" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Always keep these core principles in mind when making design decisions:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modular and flexible:`}</strong>{` All components are designed to work as reusable, customisable building blocks to help people work smarter, better, and faster.`}</li>
      <li parentName="ul"><strong parentName="li">{`Consistent:`}</strong>{` All components are designed to fit together seamlessly to ensure a consistent and cohesive user experience.`}</li>
      <li parentName="ul"><strong parentName="li">{`Accessibility baked-in:`}</strong>{` Accessibility is part of the process from start to end. It is designed to be accessible to all, regardless of ability or situation.`}</li>
    </ul>
    <h2 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}>{`Getting started`}<a parentName="h2" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ol>
      <li parentName="ol">{`Explore the City of Helsinki `}<ExternalLink href="https://brand.hel.fi/" mdxType="ExternalLink">{`Visual Identity Guidelines`}</ExternalLink>{` to learn the design principles of the brand.`}</li>
      <li parentName="ol">{`Take a look at the `}<InternalLink href="/components" mdxType="InternalLink">{`Components documentation`}</InternalLink>{` to see what is available and how you can incorporate those into your designs.`}</li>
      <li parentName="ol">{`The design assets are available either via direct design kit download or via the City of Helsinki Abstract. To use HDS libraries, you will need to install Sketch. See more on downloading and setting up the libraries below.`}</li>
      <li parentName="ol">{`If you have an idea for improvements or a component that could be a useful addition to the Design System, see the `}<InternalLink href="/getting-started/contributing/before-contributing" mdxType="InternalLink">{`Contributing`}</InternalLink>{` page for more information.`}</li>
    </ol>
    <h2 {...{
      "id": "setting-up",
      "style": {
        "position": "relative"
      }
    }}>{`Setting up`}<a parentName="h2" {...{
        "href": "#setting-up",
        "aria-label": "setting up permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "install-the-tools",
      "style": {
        "position": "relative"
      }
    }}>{`Install the tools`}<a parentName="h3" {...{
        "href": "#install-the-tools",
        "aria-label": "install the tools permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The HDS design workflow is based on `}<strong parentName="p">{`Sketch`}</strong>{` and `}<strong parentName="p">{`Abstract`}</strong>{` (if you have access to Helsinki organisation):`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.sketch.com/" mdxType="ExternalLink">Sketch</ExternalLink> is a <strong>vector graphics editor</strong> and is widely adopted by designers to create user interface designs for web and mobile services.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.abstract.com/" mdxType="ExternalLink">Abstract</ExternalLink> is a <strong>design collaboration and version and management tool</strong> that enables designers to share Sketch files and libraries easily. By leveraging and extending the technology of Git, Abstract provides design teams with a lightweight workflow and stable tools so designers can work together with confidence.
      </li>
    </ul>
    <p>{`If you are a newcomer to Sketch or Abstract, they both offer some great tutorials and help `}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.sketchapp.com/docs/" mdxType="ExternalLink">Sketch help docs</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.abstract.com/help/" mdxType="ExternalLink">Abstract help docs</ExternalLink>
      </li>
    </ul>
    <p><strong parentName="p">{`You do not necessarily need Abstract to use HDS design libraries.`}</strong>{` Abstract is a convenient way to access and update Sketch libraries, but we also offer a downloadable HDS design kit. `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/latest/download/hds-design-kit.zip" mdxType="ExternalLink">{`Download the latest HDS Design kit from the HDS GitHub repository`}</ExternalLink>{`. Please note that if you use the design kit outside of Abstract, you need to manually download the kit again to gain access to the newest updates.`}</p>
    <h3 {...{
      "id": "install-fonts",
      "style": {
        "position": "relative"
      }
    }}>{`Install fonts`}<a parentName="h3" {...{
        "href": "#install-fonts",
        "aria-label": "install fonts permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Make sure that you have the `}<ExternalLink href="https://camelot-typefaces.com/helsinki-grotesk" mdxType="ExternalLink">{`Helsinki Grotesk font`}</ExternalLink>{` installed.`}</p>
    <p>{`Helsinki Grotesk font can be purchased from `}<ExternalLink href="https://camelot-typefaces.com/helsinki-grotesk" mdxType="ExternalLink">{`Camelot Typefaces website`}</ExternalLink></p>
    <h3 {...{
      "id": "set-up-your-abstract-project",
      "style": {
        "position": "relative"
      }
    }}>{`Set up your Abstract project`}<a parentName="h3" {...{
        "href": "#set-up-your-abstract-project",
        "aria-label": "set up your abstract project permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`To set up a project in Abstract, you will need to:`}</p>
    <ol>
      <li parentName="ol">{`Create a new Abstract project`}</li>
      <li parentName="ol">{`Add at least one design (Sketch) file to the project`}</li>
      <li parentName="ol">{`Link HDS libraries to your project`}</li>
      <li parentName="ol">{`Create a branch and start making design changes`}</li>
    </ol>
    <p><strong parentName="p">{`If you are new to Abstract`}</strong>{`, please read `}<InternalLink href="/getting-started/tutorials/abstract-tutorial" mdxType="InternalLink">{`HDS Getting started with Abstract tutorial`}</InternalLink>{` for more information on how to set up and use Abstract.`}</p>
    <h2 {...{
      "id": "get-familiar-with-hds",
      "style": {
        "position": "relative"
      }
    }}>{`Get familiar with HDS`}<a parentName="h2" {...{
        "href": "#get-familiar-with-hds",
        "aria-label": "get familiar with hds permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Before you start designing, it is a good idea to get familiar with the HDS offering.`}</p>
    <p>{`For designers, a good place to start is to browse through design collections in the HDS Abstract project. `}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://share.goabstract.com/7b92ffce-5566-4aa1-be7a-3555b8d7a11c" mdxType="ExternalLink">Abstract Collection - Components</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://share.goabstract.com/1dd17c91-0b9e-463a-9492-66af1301e52a" mdxType="ExternalLink">Abstract Collection - Design Tokens</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://share.goabstract.com/cd813835-b6de-42a7-8686-0ea8aec21663" mdxType="ExternalLink">Abstract Collection - Patterns</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://share.goabstract.com/1dd17c91-0b9e-463a-9492-66af1301e52a" mdxType="ExternalLink">Abstract Collection - Design Tokens</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://share.goabstract.com/34245a50-f1a5-4160-9059-c4ccf320c7d2" mdxType="ExternalLink">Abstract Collection - Icons</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://share.goabstract.com/e6aa2ebd-1a49-4cb1-90ef-a44a6486c28b" mdxType="ExternalLink">Abstract Collection - Example Custom Components</ExternalLink>
      </li>
    </ul>
    <h3 {...{
      "id": "customising-component-symbols",
      "style": {
        "position": "relative"
      }
    }}>{`Customising component symbols`}<a parentName="h3" {...{
        "href": "#customising-component-symbols",
        "aria-label": "customising component symbols permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The component symbols use `}<em parentName="p">{`smart layout`}</em>{` for easy resizing. You can also configure the content and styling of symbol parts from the `}<em parentName="p">{`Overrides`}</em>{` section of symbol properties.`}</p>
    <p><em parentName="p">{`When changing the colour or other styles of component symbols, be sure your custom design adheres to the design guidelines.`}</em>{` Styles within components are carefully considered. We do not recommend detaching the symbol to change default styles.`}</p>
    <Image src="/images/getting-started/designers/component-overrides.png" alt="Customising symbol overrides" style={{
      "width": "100%",
      "maxWidth": "850px",
      "marginBottom": "24px"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "styling-text-elements",
      "style": {
        "position": "relative"
      }
    }}>{`Styling text elements`}<a parentName="h3" {...{
        "href": "#styling-text-elements",
        "aria-label": "styling text elements permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Linking the HDS Typography library to your project adds the possibility to give HDS text styles to your text elements. You can add text styles to your layout in two ways:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`You can add a new text element with appropriate text style from the Insert menu: Select the HDS Typography from the Text styles section, choose the desired text style, and click anywhere on your artboard. This creates a new text element with the chosen style.`}</p>
        <Image src="/images/getting-started/designers/typography-header-insert.png" alt="Inserting header styles" style={{
          "width": "100%",
          "maxWidth": "850px",
          "marginBottom": "24px"
        }} viewable mdxType="Image" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can give text styles to existing text elements by selecting the text element and changing its style from the `}<em parentName="p">{`Appearance`}</em>{` sidebar.`}</p>
        <Image src="/images/getting-started/designers/typography-textstyle-appearance.png" alt="Changing text elements style" style={{
          "width": "100%",
          "width": "100%",
          "maxWidth": "850px",
          "marginBottom": "24px"
        }} viewable mdxType="Image" />
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      